#schimg
{
  width: 100%;
  height: 500px;
}
.item-card
{
  margin-top: 40px;
}
.desc
{
  color: #7a7a7a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: left;
  line-height: 1.8em;
}
.username
{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
}
.title
{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  color:grey;
  font-size: 16px;
}
.sponsor-image
{
  height: auto;
  width: 150px;
}
.booth
{
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;
  margin-left: 35px;
  font-size: 18px;
}
.myClassname
{
  height: 500px;
}
.sponsor_conatct_form
{
  text-align: center !important;
  border-radius: 6px;
  margin-bottom: 20px;
}
.label
{
  float: left;
  left: 0;
}
.video-section
{
  display: inline-flex;
}
.btn-video-chat
{
  margin-left: 20px;
}
.contact-info
{
}
.contact-form
{
}

.exhibitor-search-div
{
  width: 30%;
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}
.exhibitor-search
{
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px 1px lightgray;
}
.sponsor-schedule-div
{
  height: 510px;
}
.sponsor-popup
{
}
.sponsor-schedule-header
{
  color: black;
}
.sponsor-schedule-time
{
  color: black;
}
.imagemaptabs-div
{
  margin: 10px 0 -25px 0;
  text-align: center;
}
.imgmap-btn1, .imgmap-btn2, .imgmap-btn3, .imgmap-btn4, .imgmap-btn5
{
  margin: 10px;
}
/*    Exhibitor New Layout  */

.exhibitor-layout-header
{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family: sans-serif;
} 
.exhibitor-filter-div
{
  margin: 0px 0px 50px 0px;
}
.exhibitor-filter-label
{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  padding-left: 30px;
}
.exhibitor-filter-select
{
  width: 65% !important;
}
.exhibitor-layout-card
{
  height: 300px;
  margin-bottom: 20px;
  border: 1.5px solid lightgreen;
  border-radius: 6px;
  font-family:sans-serif;
}
.exhibitor-layout-img
{
  height: auto;
  width: 180px;
  max-width: 100%;
  
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}
.exhibitor-layout-imgdiv
{
  padding-left: 50px;
  vertical-align: middle;
  text-align: center !important;
  display: table-cell;
  height: 230px;
  max-width: max-content;
}

.exhibitor-layout-buttondiv{
  text-align: center;
}
.exhibitor-layout-button
{
  background: transparent;
  color: black;
  padding: 10px 20px 10px 20px;
  border-radius: 50px;
  border: 1px solid lightgray;
}
.exhibitor-layout-button:hover
{
  background-color: gray !important;
  color: white !important;
}

.sponsorpage-layout-header
{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family: sans-serif;
  margin-bottom: 30px;
} 
.sponsorpage-layout-card
{
  height: 300px;
  margin-bottom: 40px;
  border: 1.5px solid lightgreen;
  border-radius: 6px;
  font-family: sans-serif;
}
.sponsorpage-layout-img
{
  height: auto;
  width: 170px;
  max-width: 100%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 100%;
  max-height: 90%;

}
.sponsor-slider
{
  margin-bottom: -50px !important;
  margin-left: 10px;
}
.sponsor-slider-img
{
  height: auto;
  max-height: 150px;
  width: 200px;
  max-width: 100%;
}
.sponsor-slider-div
{
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  height: 150px !important;
}
.sponsorpage-layout-imgdiv
{
  text-align: center;
  padding-left: 60px;
  vertical-align: middle;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  height: 230px;
}
.sponsorpage-layout-buttondiv
{
  text-align: center;
}
.sponsorpage-layout-button
{
  background: transparent;
  color: black;
  padding: 10px 20px 10px 20px;
  border-radius: 50px;
  border: 1px solid lightgray;
}
.sponsorpage-layout-button:hover
{
  background-color: gray !important;
  color: white !important;
}
.img-map
{
  margin-top: -0px !important;
  margin-bottom: 20px !important;
}
.active-img-map-tab
{
  /*background-color: peru !important;*/
}
.filter-note
{
  margin-right: -155px;
  margin-top: 10px !important;
  color: gray !important;
}
.exhibitor-desc
{
  font-family: 'Times New Roman', Times, serif !important;
}
.exhibitor-layout-boothdiv{
  text-align: center;
}

.exhibitor-file img{
  width: 35px;
}

.sponsors-details-p{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: left !important;
}
.exhibitor-file{
  margin-top: -20px !important;
  padding-bottom: 20px !important;
}

.platinum-tag,.eventvendor-tag {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.173) 0px -3px 0px inset;
  padding: 3px 7px;
  border-radius: 5px;
  float: right;
  margin-top: 6px;
  margin-right: 6px;
  font-size: 10px;
  background: lightgreen;
  color: black;
  font-weight: bold;
}
.eventvendor-tag {
  background-color: orange;
}